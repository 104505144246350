import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 

const DateRangeFilter = (props) => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const locale= {
        localize: {
          month: (n) => {
              return ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][n];
          },
          day: (n) => {
              return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'][n];
          },
      },
      formatLong: {
          month: 'MMMM yyyy',
          day: 'dd MMM yyyy',
          weekday: 'eeee',
      },
    };

    const handleSelect = ranges => {
        setSelectionRange(ranges.selection);
        if (props.onDateChange) {
            props.onDateChange(ranges.selection);
        }
    };

    return (
        <div className="date-range-filter-container">
            <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                locale={locale}
            />
        </div>
    );
};

export default DateRangeFilter;
